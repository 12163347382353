<template>
  <div class="page-wraper">
    <!-- HEADER START -->
    <main-header></main-header>
    <!-- HEADER END -->
    <!-- CONTENT START -->
    <div id="Home" class="page-content">
      <!-- SLIDER START -->
      <div id="welcome_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="goodnews-header" data-source="gallery" style="background:#eeeeee;padding:0px;">
        <div id="welcome" class="rev_slider fullscreenbanner" style="display:block;" data-version="5.4.3.1">
          <ul>
            <!-- SLIDE 1 -->
            <li data-index="rs-901" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default" data-thumb="assets/images/main-slider/slider1/slide1.jpg" data-rotate="0"
                data-fstransition="fade" data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off" data-title="" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                data-param9="" data-param10="" data-description="">
              <!-- MAIN IMAGE -->
              <img src="assets/images/main-slider/slider1/slide1.jpg" alt="" data-lazyload="assets/images/main-slider/slider1/slide1.jpg" data-bgposition="center center" data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
              <!-- LAYERS -->
              <!-- LAYER NR. 1 [ for overlay ] -->
              <div class="tp-caption tp-shape tp-shapewrapper " id="slide-901-layer-0" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
                   data-height="full" data-whitespace="nowrap" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames='[
                            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                            ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 1;background-color:rgba(255, 255, 255, 0.3);border-color:rgba(0, 0, 0, 0);border-width:0px;">
              </div>
              <!-- LAYER NR. 4 [ for title ] -->
              <div class="tp-caption   tp-resizeme" id="slide-901-layer-2" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['top','top','top','top']" data-voffset="['240','240','180','200']" data-fontsize="['72','72','62','52']"
                   data-lineheight="['82','82','72','62']" data-width="['700','700','700','90%']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on"
                   data-frames='[{"delay":"+790","speed":1500,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                   data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13;
                            white-space: normal;
                            font-weight: 700;
                            color:#111;
                            border-width:0px;font-family: 'Poppins', sans-serif; text-transform:uppercase ;">
                <div>We Build Your Dream</div>

              </div>

              <!-- LAYER NR. 5 [ for block] -->

              <div class="tp-caption rev-btn  tp-resizeme slider-block" id="slide-901-layer-3" data-x="['left','left','left','left']" data-hoffset="['0','80','80','60']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="none"
                   data-height="none" data-whitespace="nowrap" data-type="button" data-responsive_offset="on" data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                                {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]' data-textAlign="['left','left','left','left']" data-paddingtop="[250,250,250,200]" data-paddingright="[150,150,150,150]"
                   data-paddingbottom="[250,250,250,200]" data-paddingleft="[150,150,150,150]" style="z-index: 8;">
                <div class="rs-wave" data-speed="1" data-angle="0" data-radius="2px"></div>
              </div>
              <!-- LAYER NR. 5 [ for block] -->
              <div class="tp-caption   tp-resizeme" id="slide-901-layer-4" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['top','top','top','top']" data-voffset="['410','410','350','340']" data-fontsize="['20','20','38','28']"
                   data-lineheight="['28','28','48','38']" data-width="['600','600','700','600']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on"
                   data-frames='[{"delay":"+790","speed":1500,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                   data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13;
                            white-space: normal;
                            font-weight: 500;
                            color:#111;
                             border-width:0px;font-family: 'Poppins', sans-serif;">
                We take your trust seriously, employing proven quality management principles to enhance customer satisfaction and continually improve.

              </div>

              <!-- LAYER NR. 6 [ for see all service botton ] -->
              <div class="tp-caption tp-resizeme" id="slide-901-layer-5" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['top','top','top','top']" data-voffset="['500','520','540','480']" data-lineheight="['none','none','none','none']"
                   data-width="['300','300','300','300']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on" data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index:100; text-transform:uppercase;">
                <router-link tag="a" :to="{name: 'About'}" class="site-button-secondry btn-effect"><span style="color: #fff!important">More About</span></router-link>
              </div>

            </li>
            <!-- SLIDE 2 -->
            <li data-index="rs-902" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default" data-thumb="assets/images/main-slider/slider1/slide2.jpg" data-rotate="0"
                data-fstransition="fade" data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off" data-title="" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                data-param9="" data-param10="" data-description="">
              <!-- MAIN IMAGE -->
              <img src="assets/images/main-slider/slider1/slide2.jpg" alt="" data-lazyload="assets/images/main-slider/slider1/slide2.jpg" data-bgposition="center center" data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
              <!-- LAYERS -->
              <!-- LAYER NR. 1 [ for overlay ] -->
              <div class="tp-caption tp-shape tp-shapewrapper " id="slide-902-layer-0" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
                   data-height="full" data-whitespace="nowrap" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames='[
                            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                            ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 1;background-color:rgba(255, 255, 255, 0.3);border-color:rgba(0, 0, 0, 0);border-width:0px;">
              </div>
              <!-- LAYER NR. 4 [ for title ] -->
              <div class="tp-caption   tp-resizeme" id="slide-902-layer-2" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['top','top','top','top']" data-voffset="['240','240','180','200']" data-fontsize="['72','72','62','52']"
                   data-lineheight="['82','82','72','62']" data-width="['700','700','700','90%']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on"
                   data-frames='[{"delay":"+790","speed":1500,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                   data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13;
                            white-space: normal;
                            font-weight: 700;
                            color:#111;
                            border-width:0px;font-family: 'Poppins', sans-serif; text-transform:uppercase ;">
                <div>From concept to creation.</div>

              </div>

              <!-- LAYER NR. 5 [ for block] -->

              <div class="tp-caption rev-btn  tp-resizeme slider-block" id="slide-902-layer-3" data-x="['left','left','left','left']" data-hoffset="['0','80','80','60']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="none"
                   data-height="none" data-whitespace="nowrap" data-type="button" data-responsive_offset="on" data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                                {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]' data-textAlign="['left','left','left','left']" data-paddingtop="[250,250,250,200]" data-paddingright="[150,150,150,150]"
                   data-paddingbottom="[250,250,250,200]" data-paddingleft="[150,150,150,150]" style="z-index: 8;">
                <div class="rs-wave" data-speed="1" data-angle="0" data-radius="2px"></div>
              </div>
              <!-- LAYER NR. 5 [ for block] -->
              <div class="tp-caption   tp-resizeme" id="slide-902-layer-4" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['top','top','top','top']" data-voffset="['410','410','350','340']" data-fontsize="['20','20','38','28']"
                   data-lineheight="['28','28','48','38']" data-width="['600','600','700','600']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on"
                   data-frames='[{"delay":"+790","speed":1500,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                   data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13;
                            white-space: normal;
                            font-weight: 500;
                            color:#111;
                             border-width:0px;font-family: 'Poppins', sans-serif;">
                From initial design and project specification to achieving a high end finish.

              </div>

              <!-- LAYER NR. 6 [ for see all service botton ] -->
              <div class="tp-caption tp-resizeme" id="slide-902-layer-5" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['top','top','top','top']" data-voffset="['500','520','540','480']" data-lineheight="['none','none','none','none']"
                   data-width="['300','300','300','300']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on" data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index:100; text-transform:uppercase;">
                <router-link tag="a" :to="{name: 'About'}" class="site-button-secondry btn-effect"><span style="color: #fff!important">More About</span></router-link>
              </div>

            </li>
            <!-- SLIDE 3-->
            <li data-index="rs-903" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default" data-thumb="assets/images/main-slider/slider1/slide3.jpg" data-rotate="0"
                data-fstransition="fade" data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off" data-title="" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                data-param9="" data-param10="" data-description="">
              <!-- MAIN IMAGE -->
              <img src="assets/images/main-slider/slider1/slide3.jpg" alt="" data-lazyload="assets/images/main-slider/slider1/slide3.jpg" data-bgposition="center center" data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
              <!-- LAYERS -->
              <!-- LAYER NR. 1 [ for overlay ] -->
              <div class="tp-caption tp-shape tp-shapewrapper " id="slide-903-layer-0" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
                   data-height="full" data-whitespace="nowrap" data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames='[
                            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                            ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 1;background-color:rgba(255, 255, 255, 0.3);border-color:rgba(0, 0, 0, 0);border-width:0px;">
              </div>
              <!-- LAYER NR. 4 [ for title ] -->
              <div class="tp-caption   tp-resizeme" id="slide-903-layer-2" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['top','top','top','top']" data-voffset="['240','240','180','200']" data-fontsize="['72','72','62','52']"
                   data-lineheight="['82','82','72','62']" data-width="['700','700','700','90%']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on"
                   data-frames='[{"delay":"+790","speed":1500,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                   data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13;
                            white-space: normal;
                            font-weight: 700;
                            color:#111;
                            border-width:0px;font-family: 'Poppins', sans-serif; text-transform:uppercase ;">
                <div>Making dreams come to life</div>

              </div>

              <!-- LAYER NR. 5 [ for block] -->

              <div class="tp-caption rev-btn  tp-resizeme slider-block" id="slide-903-layer-3" data-x="['left','left','left','left']" data-hoffset="['0','80','80','60']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="none"
                   data-height="none" data-whitespace="nowrap" data-type="button" data-responsive_offset="on" data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                                {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]' data-textAlign="['left','left','left','left']" data-paddingtop="[250,250,250,200]" data-paddingright="[150,150,150,150]"
                   data-paddingbottom="[250,250,250,200]" data-paddingleft="[150,150,150,150]" style="z-index: 8;">
                <div class="rs-wave" data-speed="1" data-angle="0" data-radius="2px"></div>
              </div>
              <!-- LAYER NR. 5 [ for block] -->
              <div class="tp-caption   tp-resizeme" id="slide-903-layer-4" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['top','top','top','top']" data-voffset="['410','410','350','340']" data-fontsize="['20','20','38','28']"
                   data-lineheight="['28','28','48','38']" data-width="['600','600','700','600']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on"
                   data-frames='[{"delay":"+790","speed":1500,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                   data-textAlign="['left','left','left','left']" data-paddingtop="[5,5,5,5]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13;
                            white-space: normal;
                            font-weight: 500;
                            color:#111;
                             border-width:0px;font-family: 'Poppins', sans-serif;">
                A high level Quality Control in compliance with National and International regulations and standards.

              </div>

              <!-- LAYER NR. 6 [ for see all service botton ] -->
              <div class="tp-caption tp-resizeme" id="slide-903-layer-5" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['top','top','top','top']" data-voffset="['500','520','540','480']" data-lineheight="['none','none','none','none']"
                   data-width="['300','300','300','300']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on" data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index:100; text-transform:uppercase;">
                <router-link tag="a" :to="{name: 'About'}" class="site-button-secondry btn-effect"><span style="color: #fff!important">More About</span></router-link>
              </div>
            </li>
          </ul>
          <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>

          <div class="multi-line-animation">
            <div class="multi-line-one">
              <div class="animation-block1 bounce-1"></div>
            </div>
            <div class="multi-line-two">
              <div class="animation-block2 bounce-2"></div>
            </div>
            <div class="multi-line-three">
              <div class="animation-block1 bounce-1"></div>
            </div>
            <div class="multi-line-four">
              <div class="animation-block2 bounce-2"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- SLIDER END -->

      <!-- OUR SPECIALLIZATION START -->
      <div class="section-full mobile-page-padding bg-white  p-t80 p-b30 bg-repeat square_shape1" style="background-image:url(assets/images/background/bg-5.png);">
        <div class="container">
          <!-- IMAGE CAROUSEL START -->
          <div class="section-content">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 m-b30">

                <div class="image-effect-one hover-shadow">
                  <img src="assets/images/pic1.jpg" alt="" />
                  <div class="figcaption">
                    <h4>Construction</h4>
                    <p>Engineering your dreams with us.</p>
                    <a href="#"><i class="link-plus bg-primary"></i></a>
                  </div>
                </div>

              </div>
              <div class="col-lg-3 col-md-4  col-sm-6 m-b30">

                <div class="image-effect-one hover-shadow">
                  <img src="assets/images/pic2.jpg" alt="" />
                  <div class="figcaption">
                    <h4>Architecture</h4>
                    <p>Life is Architecture.</p>
                    <a href="javascript:void(0)"><i class="link-plus bg-primary"></i></a>
                  </div>

                </div>

              </div>
              <div class="col-lg-3 col-md-4  col-sm-6 m-b30">

                <div class="image-effect-one hover-shadow">
                  <img src="assets/images/pic3.jpg" alt="" />
                  <div class="figcaption bg-dark">
                    <h4>Renovation</h4>
                    <p>Dazzling Design</p>
                    <a href="javascript:void(0)"><i class="link-plus bg-primary"></i></a>
                  </div>

                </div>

              </div>

              <div class="col-lg-3 col-md-12 col-sm-6 m-b30">

                <div class="mt-box our-speciallization-content">
                  <h3 class="m-t0"><span class="font-weight-100">Building</span> <br>It better in concrete.</h3>
                  <p>When it comes to your house, don’t mess with the rest, trust the best. Making your vision come true, that is what we do.</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="hilite-title text-right p-r50 text-uppercase text-pop-up-top">
          <strong>Welcome</strong>
        </div>
      </div>
      <!-- OUR SPECIALLIZATION END -->

      <!-- ABOUT COMPANY START -->
      <div class="section-full mobile-page-padding p-t80 p-b30 bg-dark bg-repeat square_shape2 bg-moving" style="background-image:url(assets/images/background/bg-6.png)">
        <div class="container">
          <!-- TITLE START -->
          <div class="section-head">
            <div class="mt-separator-outer separator-center">
              <div class="mt-separator">
                <h2 class="text-white text-uppercase sep-line-one "><span class="font-weight-300 text-primary">About</span> Company</h2>
              </div>
            </div>
          </div>
          <!-- TITLE END -->

          <div class="section-content">
            <div class="row">
              <div class="col-md-9 col-sm-12">
                <div class="owl-carousel about-home owl-btn-vertical-center">
                  <!-- COLUMNS 1 -->
                  <div class="item ">
                    <div class="mt-img-effect zoom-slow">
                      <a href="javascript:void(0);"><img src="assets/images/gallery/pic1.jpg" alt=""></a>
                    </div>
                  </div>
                  <!-- COLUMNS 2 -->
                  <div class="item ">
                    <div class="mt-img-effect zoom-slow">
                      <a href="javascript:void(0);"><img src="assets/images/gallery/pic8.jpg" alt=""></a>
                    </div>
                  </div>
                  <!-- COLUMNS 3 -->
                  <div class="item ">
                    <div class="mt-img-effect zoom-slow">
                      <a href="javascript:void(0);"><img src="assets/images/gallery/pic9.jpg" alt=""></a>
                    </div>
                  </div>
                  <!-- COLUMNS 4 -->
                  <div class="item ">
                    <div class="mt-img-effect zoom-slow">
                      <a href="javascript:void(0);"><img src="assets/images/gallery/pic6.jpg" alt=""></a>
                    </div>
                  </div>
                  <!-- COLUMNS 5 -->
                  <div class="item ">
                    <div class="mt-img-effect zoom-slow">
                      <a href="javascript:void(0);"><img src="assets/images/gallery/pic2.jpg" alt=""></a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-12">
                <div class="about-home-right bg-white p-a30">
                  <h3 class="m-t0"><span class="font-weight-100">Committed</span> to superior quality and results.</h3>
                  <p><strong>Contrary to popular belief, We’re proud of our reputation as one of the finest “one-call, one-stop” Engineering brand and construction services.</strong></p>
                  <p>Founded by Zara Moore back in 2006, Seneca Constructions has established itself as one of the greatest and prestigious providers of construction focused interior renovation, contractor, services and building.</p>
                  <p>With over 15 years experience and real focus on customer satisfaction, you can rely on us for your next construction project, renovation, remodeling or driveway sett or home repair. We provide a professional service for private and commercial
                    customers.
                  </p>
                  <div class="text-right">
                    <router-link :to="{name: 'About'}" tag="a" class="site-button-link" data-hover="Read More">Read More <i class="fa fa-angle-right arrow-animation"></i></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hilite-title text-left p-l50 text-uppercase hilite-dark">
          <strong>About</strong>
        </div>
      </div>
      <!-- ABOUT COMPANY END -->

      <!-- OUR VALUE START -->
      <div class="section-full mobile-page-padding p-t80 p-b30 bg-white">
        <div class="container">

          <div class="section-content">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <!-- TITLE START -->
                <div class="section-head">
                  <div class="mt-separator-outer separator-left">
                    <div class="mt-separator">
                      <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Our</span> Value</h2>
                    </div>
                    <p>Bringing concept to creation, from initial design and project specification to achieving a high end finish. We are committed to superior quality and results.</p>
                  </div>
                </div>
                <!-- TITLE END -->
                <div class="author-info p-t20">

                  <div class="author-signature">
                    <img src="assets/images/SignatureBlack.png" alt="Signature" width="150">
                  </div>
                  <div class="author-name">
                    <h4 class="m-t0">Zara Moore</h4>
                    <p>Ceo & Founder</p>
                  </div>
                </div>
                <div class="video-section-full bg-no-repeat bg-cover overlay-wraper" style="background-image:url(assets/images/video-bg.jpg)">
                  <div class="overlay-main bg-black opacity-07"></div>
                  <div class="video-section-inner">
                    <div class="video-section-content">
                      <div class="video-section-left">
                        <a href="https://player.vimeo.com/video/34741214?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" class="mfp-video play-now">
                          <i class="icon fa fa-play"></i>
                          <span class="ripple"></span>
                        </a>
                      </div>
                      <div class="video-section-right">
                        <a href="https://player.vimeo.com/video/34741214?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" class="mfp-video font-weight-600 text-uppercase">Video Presentation</a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-3 col-sm-6">

                <div class="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                  <div class="counter font-30 font-weight-800 m-b15 text-primary">450</div>
                  <h4 class="m-tb0">Active Experts</h4>
                </div>
                <div class="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                  <div class="counter font-30 font-weight-800 m-b15  text-primary">670</div>
                  <h4 class="m-tb0">Satisfied Clients</h4>
                </div>
                <div class="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                  <div class="counter font-30 font-weight-800 m-b15 text-primary">1500</div>
                  <h4 class="m-tb0">Projects Completed</h4>
                </div>
              </div>
              <div class="col-md-5 col-sm-6">
                <div class="p-b0">
                  <div class="mt-box">
                    <h3 class="m-t0"><span class="font-weight-100"> We have over</span><span class="text-primary"> 15 years</span> experience in construction</h3>
                  </div>
                  <span class="progressText text-black"><B>Architecture</B></span>
                  <div class="progress mt-probar-1  m-b30 m-t10">
                    <div class="progress-bar bg-primary " role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                      <span class="popOver" data-toggle="tooltips" data-placement="top" title="85%"></span>
                    </div>
                  </div>

                  <span class="progressText text-black"><B>Construction</B></span>
                  <div class="progress mt-probar-1 m-b30 m-t10">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="78" aria-valuemin="10" aria-valuemax="100">
                      <span class="popOver" data-toggle="tooltips" data-placement="top" title="78%"></span>
                    </div>
                  </div>

                  <span class="progressText text-black"><B>Building</B></span>
                  <div class="progress mt-probar-1 m-b30 m-t10">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100">
                      <span class="popOver" data-toggle="tooltips" data-placement="top" title="65%"></span>
                    </div>
                  </div>

                  <span class="progressText text-black"><B>Interior</B></span>
                  <div class="progress mt-probar-1 m-b30 m-t10">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                      <span class="popOver" data-toggle="tooltips" data-placement="top" title="40%"></span>
                    </div>
                  </div>

                  <span class="progressText text-black"><B>Renovation</B></span>
                  <div class="progress mt-probar-1 m-b30 m-t10">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                      <span class="popOver" data-toggle="tooltips" data-placement="top" title="70%"></span>
                    </div>
                  </div>

                  <span class="progressText text-black"><B>Commercial</B></span>
                  <div class="progress mt-probar-1 m-b30 m-t10">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100">
                      <span class="popOver" data-toggle="tooltips" data-placement="top" title="55%"></span>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- OUR VALUE END -->

      <!-- OUR MISSION START -->
      <div class="section-full mobile-page-padding mission-outer-section  p-t80 p-b30 bg-gray bg-no-repeat bg-right-center" style="background-image:url(assets/images/left-men.png), url(assets/images/background/bg-4.png); ">
        <div class="section-content">
          <div class="container">
            <!-- TITLE START -->
            <div class="section-head">
              <div class="mt-separator-outer separator-center">
                <div class="mt-separator">
                  <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Our</span> Mission</h2>
                  <p>Bring to the table win-win strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined top finish solution.
                    There's nothing we wont try. Never heard the word impossible.</p>
                </div>
              </div>
            </div>
            <!-- TITLE END -->

            <div class="row">
              <div class="col-md-4 col-sm-6">
                <div class="mission-left bg-white m-b30 p-a30 bg-no-repeat bg-bottom-left" style="background-image:url(assets/images/background/bg-site.png);">
                  <h3 class="m-t0"><span class="font-weight-100"> Site Areas</span><br> what we serve</h3>
                  <p>We have the experience, personnel and resources to make the project run smoothly. We can ensure a job is done on time.</p>
                  <ul class="list-angle-right anchor-line">
                    <li><a href="#">Construction Management</a></li>
                    <li><a href="#">Pre Construction Services</a></li>
                    <li><a href="#">Contract Administration</a></li>
                    <li><a href="#">Implementation</a></li>
                    <li><a href="#">Consultation Services</a></li>
                    <li><a href="#">Renovation Services</a></li>
                  </ul>
                </div>
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="mission-mid bg-no-repeat bg-cover m-b30" style="background-image:url(assets/images/mission.jpg);"></div>
              </div>

              <div class="col-md-4 col-sm-12">
                <div class="contact-home1-left bg-dark p-a30 m-b0">
                  <h3 class="text-white m-t0"><span class="font-weight-100">Get In</span> Touch</h3>
                  <div class="flex flex-col w-full mt-2 mb-2">
                    <div class="mb-2 text-justify" v-if="response.success"><span :class="response.success ? 'text-success' : ''" class="px-1 text-justify overflow-ellipsis">{{ response.success }}</span></div>
                    <div class="mb-2 text-justify" v-if="response.error"><span :class="response.error ? 'text-danger' : ''" class="px-1 text-justify overflow-ellipsis">{{ response.error }}</span></div>
                    <br>
                  </div>
                  <form class="" @submit.prevent="initiateContact">

                    <div class="input input-animate">
                      <label for="name">Name</label>
                      <input type="text" v-model="contact.name" name="name" id="name" required>
                      <span class="text-danger" v-if="name_invalid"> {{ errors.name }}</span>
                    </div>

                    <div class="input input-animate">
                      <label for="email">Email</label>
                      <input type="email" v-model="contact.email" name="email" id="email" required>
                      <span class="text-danger" v-if="email_invalid"> {{ errors.email }}</span>
                    </div>

                    <div class="input input-animate">
                      <label for="subject">Subject</label>
                      <input type="text" v-model="contact.subject" name="subject" id="subject" required>
                      <span class="text-danger" v-if="subject_invalid"> {{ errors.subject }}</span>
                    </div>

                    <div class="input input-animate">
                      <label for="message">Message</label>
                      <textarea v-model="contact.message" name="message" id="message" required></textarea>
                      <span class="text-danger" v-if="message_invalid"> {{ errors.message }}</span>
                    </div>

                    <div class="text-center p-t10">
                      <button type="submit" class="site-button btn-effect ">
                        Submit  &nbsp;<i class="text-xl fa fa-spinner fa-spin" v-if="sendLoading"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
          <strong>Mission</strong>
        </div>
      </div>
      <!-- OUR MISSION  END -->

      <!-- OUR SERVICES START -->
      <div class="section-full mobile-page-padding  p-b50  square_shape2">
        <div class="section-content">
          <div class="Service-half-top p-t80  bg-dark bg-moving" style="background-image:url(assets/images/background/bg-6.png)">
            <div class="container">
              <!-- TITLE START -->
              <div class="section-head text-white">
                <div class="mt-separator-outer separator-left">
                  <div class="mt-separator">
                    <h2 class="text-white text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Our</span> Services</h2>
                  </div>
                </div>
                <h3>Work with us involve a carefully planned series of steps, centered around a schedule we stick to and frequent communication.</h3>
              </div>
              <!-- TITLE END -->
            </div>
          </div>
          <div class="services-half-bottom">
            <div class="container">
              <div class="row">
                <div class="col-md-3 col-sm-6">

                  <div class="mt-icon-box-wraper m-b30">
                    <div class="relative icon-count-2 bg-gray p-a30 p-tb50">
                      <span class="icon-count-number">1</span>
                      <div class="icon-md inline-icon m-b15 text-primary scale-in-center">
                        <span class="icon-cell"><img src="assets/images/icon/crane-1.png" alt=""></span>
                      </div>
                      <div class="icon-content">
                        <h4 class="mt-tilte m-b25">Building <br>Construction</h4>
                        <p>From initial design and project specification to achieving a high end finish.</p>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-md-3 col-sm-6">

                  <div class="mt-icon-box-wraper m-b30">
                    <div class="relative icon-count-2 bg-gray p-a30 p-tb50">
                      <span class="icon-count-number">2</span>
                      <div class="icon-md inline-icon m-b15 text-primary scale-in-center">
                        <span class="icon-cell"><img src="assets/images/icon/renovation.png" alt=""></span>
                      </div>
                      <div class="icon-content">
                        <h4 class="mt-tilte m-b25">Building <br>Renovation</h4>
                        <p>We can help you bring new life to existing structures and develop unused spaces.</p>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-md-3 col-sm-6">

                  <div class="mt-icon-box-wraper m-b30">
                    <div class="relative icon-count-2 bg-gray p-a30 p-tb50">
                      <span class="icon-count-number">3</span>
                      <div class="icon-md inline-icon m-b15 text-primary scale-in-center">
                        <span class="icon-cell"><img src="assets/images/icon/toolbox.png" alt=""></span>
                      </div>
                      <div class="icon-content">
                        <h4 class="mt-tilte m-b25">Architecture <br>Design</h4>
                        <p>We have the experience, personnel and resources to make your imaginations come alive.</p>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-md-3 col-sm-6">

                  <div class="mt-icon-box-wraper m-b30">
                    <div class="relative icon-count-2 bg-gray p-a30 p-tb50">
                      <span class="icon-count-number">4</span>
                      <div class="icon-md inline-icon m-b15 text-primary scale-in-center">
                        <span class="icon-cell"><img src="assets/images/icon/compass.png" alt=""></span>
                      </div>
                      <div class="icon-content">
                        <h4 class="mt-tilte m-b25">Contractor<br>Services</h4>
                        <p>We combine quality workmanship, superior knowledge and low prices to provide you with service unmatched by our competitors.</p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
          <strong>Services</strong>
        </div>
      </div>
      <!-- OUR SERVICES  END -->

      <!-- CALL US SECTION START -->
      <div class="section-full mobile-page-padding p-tb80 overlay-wraper bg-cover bg-center" style="background-image:url(assets/images/background/bg-1.jpg)">
        <div class="overlay-main bg-primary opacity-07"></div>
        <div class="container">

          <div class="section-content">
            <div class="call-us-section text-center">
              <h4 class="m-b15">Let's work together</h4>
              <h2 class="call-us-number m-b15 m-b0">+1 (901) 246‑8171</h2>
              <h4 class="call-us-address m-t0 m-b20">09, Martin Street B190 Polo Alto, San Francisco</h4>
              <router-link tag="a" :to="{name: 'Contact'}" class="site-button-secondry btn-effect bg-dark"><span style="color: #fff!important">Contact Us</span></router-link>
            </div>
          </div>

        </div>
      </div>
      <!-- CALL US SECTION END -->

      <!-- OUR PROJECT START -->
      <div class="section-full mobile-page-padding p-t90 p-b30 square_shape2" id="_projects">
        <div class="container">
          <!-- TITLE START -->
          <div class="section-head">
            <div class="mt-separator-outer separator-left">
              <div class="mt-separator">
                <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Our</span> Project</h2>
                <p>Here are a few of many projects we have completed for our clients. We provide a professional service which includes consultation, free estimate, design, supply of materials and installation.</p>
              </div>
            </div>
          </div>
          <!-- TITLE END -->

          <div class="section-content">

            <div class="portfolio-wrap row mfp-gallery product-stamp clearfix">
              <!-- COLUMNS 1 -->
              <div class="stamp col-md-3 col-sm-6 m-b30">
                <div class="bg-gray p-a30">
                  <div class="filter-wrap">
                    <ul class="filter-navigation masonry-filter text-uppercase">
                      <li class="active"><a data-filter="*" data-hover="All" href="#">All</a></li>
                      <li><a data-filter=".cat-1" data-hover="Commercial" href="javascript:;">Commercial</a></li>
                      <li><a data-filter=".cat-4" data-hover="Education" href="javascript:;">Education</a></li>
                      <li><a data-filter=".cat-2" data-hover="Hospital" href="javascript:;">Hospital </a></li>
                      <li><a data-filter=".cat-3" data-hover="Residentia" href="javascript:;">Residential</a></li>
                      <li><a data-filter=".cat-5" data-hover="Office" href="javascript:;">Office</a></li>
                      <li><a data-filter=".cat-6" data-hover="Reconstruction" href="javascript:;">Reconstruction </a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- COLUMNS 2 -->
              <div class="masonry-item  cat-2 col-md-3 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/portrait/pic1.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Chair Furniture</h4>
                    <p class="m-b0">Muscat, Sultanate of Oman</p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>

              <!-- COLUMNS 3 -->
              <div class="masonry-item  cat-2 col-md-3 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/portrait/pic2.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Furniture</h4>
                    <p class="m-b0">North House</p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>

              <!-- COLUMNS 4 -->
              <div class="masonry-item  cat-6 col-md-3 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/portrait/pic3.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Living room</h4>
                    <p class="m-b0">Aqaba, Jordan</p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>

              <!-- COLUMNS 5 -->
              <div class="masonry-item  cat-6 col-md-3 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/portrait/pic4.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Dream Workspace</h4>
                    <p class="m-b0">Perth, Australia </p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>

              <!-- COLUMNS 6 -->
              <div class="masonry-item  cat-3 col-md-3 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/portrait/pic5.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Decore</h4>
                    <p class="m-b0">Aqaba, Jordan</p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>

              <!-- COLUMNS 7 -->
              <div class="masonry-item  cat-3 col-md-3 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/portrait/pic6.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Modern Decoration</h4>
                    <p class="m-b0">Muscat, Sultanate of Oman</p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>

              <!-- COLUMNS 8 -->
              <div class="masonry-item  cat-1 col-md-3 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/portrait/pic7.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Bathroom</h4>
                    <p class="m-b0">North House</p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>

              <!-- COLUMNS 11 -->
              <div class="masonry-item  cat-6 col-md-6 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/pic-l-5.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Lamp decoration</h4>
                    <p class="m-b0">Aqaba, Jordan</p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>
              <!-- COLUMNS 9 -->
              <div class="masonry-item  cat-5 col-md-3 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/pic-1.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Building house</h4>
                    <p class="m-b0">Ultanate of Oman </p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>

              <!-- COLUMNS 10 -->
              <div class="masonry-item  cat-4 col-md-3 col-sm-6 m-b30">
                <div class="mt-box   image-hover-block">
                  <div class="mt-thum-bx">
                    <img src="assets/images/projects/pic-2.jpg" alt="">
                  </div>
                  <div class="mt-info  p-t20 text-white">
                    <h4 class="mt-tilte m-b10 m-t0">Spa residence</h4>
                    <p class="m-b0">Perth, Australia </p>
                  </div>
                  <a href="javascript:void(0);"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
          <strong>Projects</strong>
        </div>
      </div>
      <!-- OUR PROJECT END -->

      <!-- TESTIMONIALS SECTION START -->
      <div class="section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover" style="background-image:url(assets/images/background/bg6.jpg);">
        <div class="container">
          <div class="section-content">

            <!-- TITLE START -->
            <div class="section-head">
              <div class="mt-separator-outer separator-center">
                <div class="mt-separator">
                  <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Client</span> Testimonials</h2>
                </div>
              </div>
            </div>
            <!-- TITLE END -->

            <!-- TESTIMONIAL START -->
            <div class="owl-carousel testimonial-home">
              <div class="item">
                <div class="testimonial-2 m-a30 hover-animation-1">
                  <div class=" block-shadow bg-white p-a30">
                    <div class="testimonial-detail clearfix">
                      <div class="testimonial-pic radius shadow scale-in-center"><img src="assets/images/testimonials/pic1.jpg" width="100" height="100" alt=""></div>
                      <h4 class="testimonial-name m-b5">Shelly Johnson -</h4>
                      <span class="testimonial-position">Realtor</span>
                    </div>
                    <div class="testimonial-text">
                      <span class="fa fa-quote-right"></span>
                      <p>"We would like to thank Seneca Constructions Company for an outstanding effort on our recently completed project located in the Moscow. The project involved a very aggressive schedule and it was completed on time. We would certainly like to use their professional services again."</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimonial-2 m-a30  hover-animation-1">
                  <div class=" block-shadow bg-white p-a30">
                    <div class="testimonial-detail clearfix">
                      <div class="testimonial-pic radius shadow scale-in-center"><img src="assets/images/testimonials/pic2.jpg" width="100" height="100" alt=""></div>
                      <h4 class="testimonial-name m-b5">Cuthbert Brain -</h4>
                      <span class="testimonial-position">Business Person</span>
                    </div>
                    <div class="testimonial-text">
                      <span class="fa fa-quote-right"></span>
                      <p>Their attention to details is second to none. They offer quality tiling and painting solutions for interior and exterior. Great company!.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimonial-2 m-a30  hover-animation-1">
                  <div class=" block-shadow bg-white p-a30">
                    <div class="testimonial-detail clearfix">
                      <div class="testimonial-pic radius shadow scale-in-center"><img src="assets/images/testimonials/pic3.jpg" width="100" height="100" alt=""></div>
                      <h4 class="testimonial-name m-b5">Mac Wagner -</h4>
                      <span class="testimonial-position">Contractor</span>
                    </div>
                    <div class="testimonial-text">
                      <span class="fa fa-quote-right"></span>
                      <p>Today we can tell you, thanks to your passion, hard work, creativity, and expertise, you delivered us the most beautiful house great looks.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
          <strong>Clients</strong>
        </div>
      </div>
      <!-- TESTIMONIALS SECTION END -->
    </div>
    <div id="About" class="page-content">

      <!-- INNER PAGE BANNER -->
      <div class="mt-bnr-inr overlay-wraper bg-parallax bg-top-center" data-stellar-background-ratio="0.5" style="background-image:url(assets/images/banner/1.jpg);">
        <div class="overlay-main bg-black opacity-07"></div>
        <div class="container">
          <div class="mt-bnr-inr-entry">
            <div class="banner-title-outer">
              <div class="banner-title-name">
                <h2 class="m-b0">Creating quality urban lifestyles, building stronger communities.</h2>
              </div>
            </div>
            <!-- BREADCRUMB ROW -->

            <div>
              <ul class="mt-breadcrumb breadcrumb-style-2">
                <li><a href="javascript:void(0);">Home</a></li>
                <li>About</li>
              </ul>
            </div>

            <!-- BREADCRUMB ROW END -->
          </div>
        </div>
      </div>
      <!-- INNER PAGE BANNER END -->

      <!-- ABOUT COMPANY SECTION START -->
      <div class="section-full p-tb80 bg-white inner-page-padding">
        <div class="container">
          <!-- TITLE START -->
          <div class="section-head">
            <div class="mt-separator-outer separator-left">
              <div class="mt-separator">
                <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">About</span> Company</h2>
              </div>
            </div>
          </div>
          <!-- TITLE END -->
          <div class="section-content ">
            <div class="row">
              <div class="col-md-5 col-sm-6">
                <div class="m-about ">
                  <div class="owl-carousel about-us-carousel owl-loaded owl-drag">
                    <!-- COLUMNS 1 -->
                    <div class="item">
                      <div class="ow-img">
                        <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic4.jpg" alt=""></a>
                      </div>
                    </div>
                    <!-- COLUMNS 2 -->
                    <div class="item">
                      <div class="ow-img">
                        <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic5.jpg" alt=""></a>
                      </div>
                    </div>
                    <!-- COLUMNS 3 -->
                    <div class="item">
                      <div class="owl-img">
                        <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic6.jpg" alt=""></a>
                      </div>
                    </div>
                    <!-- COLUMNS 4 -->
                    <div class="item">
                      <div class="ow-img">
                        <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic7.jpg" alt=""></a>
                      </div>
                    </div>
                    <!-- COLUMNS 5 -->
                    <div class="item">
                      <div class="ow-img">
                        <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic1.jpg" alt=""></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-sm-6">
                <div class="m-about-containt text-black p-t80">
                  <div class="m-about-years bg-moving" style="background-image:url(assets/images/background/line.png);">
                    <span class="text-primary large-title">15</span>
                    <span class="large-title-info">Years of Experience</span>
                  </div>
                  <h3 class="font-weight-600">Improving quality of life with an integrated unified approach.</h3>
                  <p>Founded by Zara Moore back in 2006, Seneca Construction has established itself as one of the greatest and prestigious providers of construction focused renovation, building contractor, infrastructural services and more.</p>
                  <p>With over 15 years experience and real focus on customer satisfaction, you can rely on us for your next infrastructure construction, renovation, remodeling or driveway sett or home repair. We provide a professional service for private and commercial
                    customers.
                  </p>
                  <p>We provide a professional general construction, renovation and installation services with a real focus on customer satisfaction. Our engagements are carried out by fully trained staff to the highest professional standards.</p>
                  <div class="author-info p-t20">

                    <div class="author-signature">
                      <img src="assets/images/SignatureBlack.png" alt="Signature" width="150" />
                    </div>

                    <div class="author-name">
                      <h4 class="m-t0">Zara Moore</h4>
                      <p>Founder</p>
                    </div>
                    <router-link :to="{name: 'Contact'}" tag="a" class="site-button btn-effect m-b15"><span style="color: #fff!important">Get in touch</span></router-link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- ABOUT COMPANY SECTION END -->

      <!-- OUR SPECIALLIZATION START -->
      <div class="section-full p-tb80 bg-white bg-repeat square_shape2 inner-page-padding" style="background-image:url(assets/images/background/ptn-1.png)">

        <div class="container">

          <!-- TITLE START -->
          <div class="section-head">
            <div class="mt-separator-outer separator-left">
              <div class="mt-separator">
                <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Our</span> Specialization</h2>
              </div>
            </div>
          </div>
          <!-- TITLE END -->

          <!-- IMAGE CAROUSEL START -->
          <div class="section-content">
            <div class="row">
              <div class="col-md-3 col-sm-6">

                <div class="mt-icon-box-wraper m-b30">
                  <div class="relative icon-count-2 bg-gray p-a30 p-tb50">
                    <span class="icon-count-number">1</span>
                    <div class="icon-md inline-icon m-b15 text-primary scale-in-center">
                      <span class="icon-cell"><img src="assets/images/icon/crane-1.png" alt=""></span>
                    </div>
                    <div class="icon-content">
                      <h4 class="mt-tilte m-b25">Building <br>Construction</h4>
                      <p>From initial design and project specification to achieving a high end finish.</p>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-3 col-sm-6">

                <div class="mt-icon-box-wraper m-b30">
                  <div class="relative icon-count-2 bg-gray p-a30 p-tb50">
                    <span class="icon-count-number">2</span>
                    <div class="icon-md inline-icon m-b15 text-primary scale-in-center">
                      <span class="icon-cell"><img src="assets/images/icon/renovation.png" alt=""></span>
                    </div>
                    <div class="icon-content">
                      <h4 class="mt-tilte m-b25">Building <br>Renovation</h4>
                      <p>We can help you bring new life to existing structures and develop unused spaces.</p>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-3 col-sm-6">

                <div class="mt-icon-box-wraper m-b30">
                  <div class="relative icon-count-2 bg-gray p-a30 p-tb50">
                    <span class="icon-count-number">3</span>
                    <div class="icon-md inline-icon m-b15 text-primary scale-in-center">
                      <span class="icon-cell"><img src="assets/images/icon/compass.png" alt=""></span>
                    </div>
                    <div class="icon-content">
                      <h4 class="mt-tilte m-b25">Architecture<br>Design</h4>
                      <p>We have the experience, personnel and resources to make your imaginations come alive.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6">
                <div class="mt-icon-box-wraper m-b30">
                  <div class="relative icon-count-2 bg-gray p-a30 p-tb50">
                    <span class="icon-count-number">4</span>
                    <div class="icon-md inline-icon m-b15 text-primary scale-in-center">
                      <span class="icon-cell"><img src="assets/images/icon/toolbox.png" alt=""></span>
                    </div>
                    <div class="icon-content">
                      <h4 class="mt-tilte m-b25">Contractor <br>Services</h4>
                      <p>We combine quality workmanship, superior knowledge and low prices to provide you with service unmatched by our competitors.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
      <!-- OUR SPECIALLIZATION END -->

      <!-- OUR TEAM START -->
      <div class="section-full p-t80 p-b50 bg-white inner-page-padding">
        <div class="container">

          <!-- TITLE START -->
          <div class="section-head">
            <div class="mt-separator-outer separator-left">
              <div class="mt-separator">
                <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Our</span> Team</h2>
              </div>
            </div>
          </div>
          <!-- TITLE END -->

          <!-- IMAGE Team START -->
          <div class="section-content">
            <div class="row" v-if="profiles.length > 0">
              <div class="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30" v-for="(profile, index) in profiles" :key="index">
                <div class="our-team-1 hover-animation-1">
                  <router-link :to="{name: 'Profile', params: {profile: `profile-${profile.firstname?.toLowerCase()}-${index}`}}">
                    <div class="profile-image scale-in-center"><img :src="`${profile.imagePath}`" alt="..." /></div>
                    <div class="figcaption">
                      <h4>{{profile.firstname}} {{profile.lastname}} </h4>
                      <h5>{{profile.jobTitle}}</h5>
                      <p></p>
                      <div class="icons">
                        <a href="#"><i class="fa fa-facebook"></i></a>
                        <a href="#"> <i class="fa fa-twitter"></i></a>
                        <a href="#"> <i class="fa fa-instagram"></i></a>
                        <a href="#"><i class="fa fa-rss"></i></a>
                        <a href="#"> <i class="fa fa-youtube"></i></a>
                        <a href="#"> <i class="fa fa-linkedin"></i></a>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="row" v-else>

              <div class="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30   ">
                <div class="our-team-1 hover-animation-1">
                  <div class="profile-image scale-in-center"><img src="assets/images/our-team4/pic1.jpeg" alt="" /></div>
                  <div class="figcaption">
                    <h4>Zara Moore </h4>
                    <h5>Ceo & Founder</h5>
                    <p></p>
                    <div class="icons">
                      <a href="#"><i class="fa fa-facebook"></i></a>
                      <a href="#"> <i class="fa fa-twitter"></i></a>
                      <a href="#"> <i class="fa fa-instagram"></i></a>
                      <a href="#"><i class="fa fa-rss"></i></a>
                      <a href="#"> <i class="fa fa-youtube"></i></a>
                      <a href="#"> <i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30   ">
                <div class="our-team-1 hover-animation-1">
                  <div class="profile-image scale-in-center"><img src="assets/images/our-team4/pic3.jpeg" alt="" /></div>
                  <div class="figcaption">
                    <h4>Sophia William </h4>
                    <h5>Co-ceo</h5>
                    <p></p>
                    <div class="icons">
                      <a href="#"><i class="fa fa-facebook"></i></a>
                      <a href="#"> <i class="fa fa-twitter"></i></a>
                      <a href="#"> <i class="fa fa-instagram"></i></a>
                      <a href="#"><i class="fa fa-rss"></i></a>
                      <a href="#"> <i class="fa fa-youtube"></i></a>
                      <a href="#"> <i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30   ">
                <div class="our-team-1 hover-animation-1">
                  <div class="profile-image scale-in-center"><img src="assets/images/our-team4/pic2.png" alt="" /></div>
                  <div class="figcaption">
                    <h4>Ben Jonson </h4>
                    <h5>Architect </h5>
                    <p></p>
                    <div class="icons">
                      <a href="#"><i class="fa fa-facebook"></i></a>
                      <a href="#"> <i class="fa fa-twitter"></i></a>
                      <a href="#"> <i class="fa fa-instagram"></i></a>
                      <a href="#"><i class="fa fa-rss"></i></a>
                      <a href="#"> <i class="fa fa-youtube"></i></a>
                      <a href="#"> <i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- OUR TEAM END -->

      <!-- OUR STORY SECTION START -->
      <div class="section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding" style="background-image:url(assets/images/background/bg-6.png)">
        <div class="container">
          <!-- TITLE START -->
          <div class="section-head">
            <div class="mt-separator-outer separator-left">
              <div class="mt-separator text-white">
                <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">About</span> Summary</h2>
              </div>
            </div>
          </div>
          <!-- TITLE END -->

          <div class="section-content our-story">

            <div class="row">
              <div class="col-md-4 col-sm-6">
                <div class="our-story-pic-block">
                  <div class="mt-media our-story-pic">
                    <img src="assets/images/gallery/portrait/pic4.jpg" alt="">
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-6">
                <div class="mt-box our-story-detail bg-moving bg-cover" style="background-image:url(assets/images/background/line.png)">
                  <h4 class="text-uppercase m-b20">2011-12</h4>
                  <h4 class="m-b20">Eisenhower Memoria</h4>
                  <p></p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 col-sm-6">
                <div class="our-story-pic-block">
                  <div class="mt-media our-story-pic">
                    <img src="assets/images/gallery/portrait/pic7.jpg" alt="">
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-6">
                <div class="mt-box our-story-detail bg-moving bg-cover" style="background-image:url(assets/images/background/line.png)">
                  <h4 class="text-uppercase m-b20">2013-14</h4>
                  <h4 class="m-b20">Columbia Sport Center</h4>
                  <p></p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 col-sm-6">
                <div class="our-story-pic-block">
                  <div class="mt-media our-story-pic">
                    <img src="assets/images/gallery/portrait/pic6.jpg" alt="">
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-6">
                <div class="mt-box our-story-detail bg-moving bg-cover" style="background-image:url(assets/images/background/line.png)">
                  <h4 class="text-uppercase m-b20">2015-16</h4>
                  <h4 class="m-b20">Park Boathouse</h4>
                  <p></p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 col-sm-6">
                <div class="our-story-pic-block">
                  <div class="mt-media our-story-pic">
                    <img src="assets/images/gallery/portrait/pic5.jpg" alt="">
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-6">
                <div class="mt-box our-story-detail bg-moving bg-cover" style="background-image:url(assets/images/background/line.png)">
                  <h4 class="text-uppercase m-b20">2017-18</h4>
                  <h4 class="m-b20">Museum Expansion</h4>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- OUR STORY SECTION END -->

    </div>
    <div id="otherView">
      <router-view></router-view>
    </div>
    <!-- CONTENT END -->
    <!-- FOOTER START -->
    <main-footer />
    <!-- FOOTER END -->
  </div>
  <!-- LOADING AREA START ===== -->
  <div class="loading-area">
    <div class="loading-box"></div>
    <div class="loading-pic">
      <div class="cssload-loader">Loading</div>
    </div>
  </div>
  <!-- LOADING AREA  END ====== -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import emailJs from '@emailjs/browser'
import mainFooter from './components/Footer'
import MainHeader from './components/Header'

export default {
  name: 'App',
  data () {
    return {
      routeName: 'Home',
      contact: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      response: {
        success: '',
        error: ''
      },
      errors: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      sendLoading: false
    }
  },
  components: {
    MainHeader,
    mainFooter
  },
  computed: {
    ...mapGetters([
      'profiles',
      'errors'
    ]),
    name_invalid () {
      return this.contact.name.length < 3
    },
    email_invalid () {
      return this.contact.email.length < 7
    },
    subject_invalid () {
      return this.contact.subject.length < 5
    },
    message_invalid () {
      return this.contact.message.length < 5
    }
  },
  watch: {
    $route (to, from) {
      const home = document.getElementById('Home')
      const about = document.getElementById('About')
      const otherView = document.getElementById('otherView')
      if (to.name === 'Home') {
        home.style.display = 'block'
        about.style.display = 'none'
        otherView.style.display = 'none'
      } else if (to.name === 'About') {
        about.style.display = 'block'
        otherView.style.display = 'none'
        home.style.display = 'none'
      } else {
        otherView.style.display = 'block'
        about.style.display = 'none'
        home.style.display = 'none'
      }
    }
  },
  methods: {
    ...mapActions([
      'getProfiles',
      'resetErrorMessages'
    ]),
    resetErrors () {
      this.errors.name = this.errors.email = this.errors.subject = this.errors.message = ''
    },
    sendContactEmail () {
      this.resetErrors()
      this.sendLoading = true

      emailJs.send('service_on27san', 'template_hjcadav', {
        to_name: 'Admin',
        from_name: 'Seneca Constructions',
        to_email: 'Sophiamoore442@gamil.com',
        name: this.contact.name,
        email: this.contact.email,
        subject: this.contact.subject,
        message: this.contact.message
      }, '3AIBRbHHGrLrXkFtm')
        .then((result) => {
          this.sendLoading = false
          this.contact.name = this.contact.subject = this.contact.email = this.contact.message = ''
          if (result.status === 200 && result.text === 'OK') {
            this.response.success = 'Email sent successfully.'
            setTimeout(() => {
              this.response.success = ''
            }, 5000)
          } else {
            this.sendLoading = false
            this.response.error = 'Oops! something went wrong. Please try again later.'
            setTimeout(() => {
              this.response.error = ''
            }, 7000)
          }
        }).catch(() => {
          this.sendLoading = false
          this.response.error = 'Oops! something went wrong. Please try again later.'
          setTimeout(() => {
            this.response.error = ''
          }, 7000)
        })
    },
    initiateContact () {
      if (this.contact.name.length < 3) {
        this.errors.name = 'Please enter a valid name'
        this.sendLoading = false
        return true
      }
      if (this.contact.email.length < 7) {
        this.errors.email = 'Please enter a valid email'
        this.sendLoading = false
        return true
      }
      if (this.contact.subject.length < 5) {
        this.errors.subject = 'Please enter a valid subject'
        this.sendLoading = false
        return true
      }
      if (this.contact.message.length < 5) {
        this.errors.message = 'Please enter a valid message'
        this.sendLoading = false
        return true
      }
      this.sendContactEmail()
    }
  },
  created () {
    this.resetErrors()
    this.getProfiles()
  }
}
</script>
