import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
  path: '/',
  name: 'Home',
  component: () =>
    import('../views/Home'),
  meta: {
    title: 'Seneca Constructions | Home'
  }
},
{
  path: '/about',
  name: 'About',
  component: () =>
    import('../views/About'),
  meta: {
    title: 'Seneca Constructions | About'
  }
},
{
  path: '/contact',
  name: 'Contact',
  component: () =>
    import('../views/Contact'),
  meta: {
    title: 'Seneca Constructions | Contact Us'
  }
},
{
  path: '/faq',
  name: 'Faq',
  component: () =>
    import('../views/Faq'),
  meta: {
    title: 'Seneca Constructions | FAQ'
  }
},
{
  path: '/services',
  name: 'Services',
  component: () => import('../views/Services'),
  meta: {
    title: 'Seneca Constructions | Services'
  }
},
{
  path: '/:profile',
  name: 'Profile',
  component: () => import('../views/Profile'),
  meta: {
    title: 'Seneca Constructions | Profile'
  }
},
{
  path: '/godmode-profile-setup',
  name: 'ProfileSetup',
  component: () => import('../views/ProfileSetup'),
  meta: {
    title: 'Seneca Constructions | Profile Setup'
  }
},
{
  path: '/*',
  name: 'Redirect',
  beforeEnter: (to, from, next) => { next('/error') }
},
{
  path: '/error',
  name: 'Error',
  component: () => import('../views/Error'),
  meta: {
    title: 'Seneca Constructions | Not Found'
  }
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split('#')[1]
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: 'smooth' })
      }
    } else if (SavedPosition) {
      return SavedPosition
    } else {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    }
  }
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Seneca Constructions'

  next()
})

export default router
