<template>
  <!-- FOOTER START -->
  <footer class="site-footer footer-large  footer-dark footer-wide">
    <div class="container call-to-action-wrap bg-no-repeat bg-center" style="background-image:url(assets/images/background/bg-site.png);">
      <div class="p-a30 bg-primary ">
        <div class="row">
          <div class="col-md-8 col-sm-8">
            <div class="call-to-action-left text-black">
              <h4 class="text-uppercase m-b10 m-t0">Subscribe to our newsletter!</h4>
              <span>Never miss anything from Seneca Construction by signing up to our Newsletter.</span>
            </div>
          </div>

          <div class="col-md-4 col-sm-4">
            <div class="call-to-action-right">
              <div class="widget_newsletter">
                <div class="newsletter-bx">
                  <form @submit.prevent="newsLetterSubscribe">
                    <div class="input-group">
                      <input name="news-letter" class="form-control" v-model="newsLetterEmail" required placeholder="Enter your email" type="email">
                      <span class="input-group-btn">
                      <button type="submit" class="site-button"><i class="fa fa-spinner fa-spin" v-if="newsLetterActive"></i><i class="fa fa-paper-plane-o" v-else></i></button>
                      </span>
                    </div>
                    <span v-if="newsLetterMsg" style="color: #fff">{{newsLetterMsg}}</span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FOOTER BLOCKES START -->
    <div class="footer-top overlay-wraper">
      <div class="overlay-main"></div>
      <div class="container">
        <div class="row">
          <!-- ABOUT COMPANY -->
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="widget widget_about">
              <!--<h4 class="widget-title">About Company</h4>-->
              <div class="logo-footer clearfix p-b15">
                <a href="/"><img src="assets/images/seneca-logo.png" alt=""></a>
              </div>
              <p class="max-w400">We have the experience, personnel and resources to make the project run smoothly. We can ensure a job is done on time.</p>

              <ul class="social-icons  mt-social-links">
                <li>
                  <a href="javascript:void(0);" class="fa fa-google"></a>
                </li>
                <li>
                  <a href="javascript:void(0);" class="fa fa-rss"></a>
                </li>
                <li>
                  <a href="javascript:void(0);" class="fa fa-facebook"></a>
                </li>
                <li>
                  <a href="javascript:void(0);" class="fa fa-twitter"></a>
                </li>
                <li>
                  <a href="javascript:void(0);" class="fa fa-linkedin"></a>
                </li>
              </ul>
            </div>
          </div>

          <!-- RESENT POST -->
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="widget widget_address_outer">
              <h4 class="widget-title">Contact Us</h4>
              <ul class="widget_address">
                <li>09, Martin Street B190 Polo Alto, San Francisco</li>
                <li> hello@senecaconstructs.com</li>
                <li>+1 (901) 246‑8171</li>
              </ul>

            </div>
          </div>

          <!-- USEFUL LINKS -->
          <div class="col-lg-4 col-md-6 col-sm-6 footer-col-3">
            <div class="widget widget_services inline-links">
              <h4 class="widget-title">Useful links</h4>
              <ul>
                <li><router-link :to="{name: 'About'}">About</router-link></li>
                <li><router-link :to="{name: 'Contact'}">Contact Us</router-link></li>
              </ul>
            </div>
          </div>
          <!-- NEWSLETTER -->

        </div>
      </div>
    </div>
    <!-- FOOTER COPYRIGHT -->
    <div class="footer-bottom overlay-wraper">
      <div class="overlay-main"></div>
      <div class="container">
        <div class="row">
          <div class="mt-footer-bot-center">
            <span class="copyrights-text">© {{ year }} Seneca Construction<router-link :to="{name: 'ProfileSetup'}" style="color: darkgrey!important;">s</router-link></span>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- FOOTER END -->

  <!-- BUTTON TOP START -->
  <button class="scroltop"><span class="fa fa-angle-up  relative" id="btn-vibrate"></span></button>
</template>

<script>
export default {
  name: 'mainFooter',
  data () {
    return {
      newsLetterActive: false,
      newsLetterMsg: '',
      newsLetterEmail: '',
      year: ''
    }
  },
  methods: {
    newsLetterSubscribe () {
      this.newsLetterActive = true
      setTimeout(() => {
        this.newsLetterMsg = 'Subscription successful.'
        this.newsLetterActive = false
        this.newsLetterEmail = ''
      }, 3000)
      setTimeout(() => {
        this.newsLetterMsg = ''
      }, 6000)
    }
  },
  created () {
    this.year = new Date().getFullYear()
  }
}
</script>
<style>
</style>
