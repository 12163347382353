import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    profiles: [],
    profile: {},
    errors: {},
    imagePath: ''
  },
  getters: {
    profiles: state => state.profiles,
    profile: state => state.profile,
    errors: state => state.errors,
    imagePath: state => state.imagePath
  },
  mutations: {
    SET_PROFILES (state, payload) {
      state.profiles = payload
    },
    PUSH_TO_PROFILES (state, payload) {
      state.profiles.push(payload)
    },
    SET_IMAGE_PATH (state, imageUrl) {
      state.imagePath = imageUrl
    },
    UPDATE_PROFILE (state, payload) {
      state.profile = payload
    },
    GET_PROFILE_BY_INDEX (state, profileData) {
      state.profile = profileData
    },
    SET_ERROR_MESSAGE (state, errorMessage) {
      state.errors.message = errorMessage
    }
  },
  actions: {
    updateProfile ({ commit, state }, profileIndex) {
      const profile = state.profiles.find(function (_entry, index) {
        if (index === parseInt(profileIndex)) return _entry
      })
      commit('UPDATE_PROFILE', profile)
    },
    getProfileByIndex ({ commit }, profileIndex) {
      commit('GET_PROFILE_BY_INDEX', profileIndex)
    },
    getProfiles ({ commit }) {
      axios
        .get('https://old-haze-7418.fly.dev/api/v1/users')
        .then((response) => {
          commit('SET_PROFILES', response.data?.data)
        })
        .catch((err) => {
          commit('SET_ERROR_MESSAGE', err.message)
          return Promise.reject(err)
        })
    },
    deleteProfile ({ commit, state }, profileId) {
      axios
        .delete(`https://old-haze-7418.fly.dev/api/v1/users/${profileId}`)
        .then((_response) => {
          const data = state.profiles.filter((element, index) => element._id !== profileId)
          commit('SET_PROFILES', data)
        })
        .catch((err) => {
          commit('SET_ERROR_MESSAGE', err.message)
          return Promise.reject(err)
        })
    },
    resetErrorMessages ({ state }) {
      state.errors = {}
    }
  },
  modules: {}
})
