<template>
  <!-- HEADER START -->
  <header class="site-header header-style-1">
    <div class="top-bar bg-gray">
      <div class="container">
        <div class="row">
          <div class="mt-topbar-left clearfix">
            <ul class="list-unstyled e-p-bx pull-right">
              <li><i class="fa fa-envelope"></i> hello@senecaconstructs.com</li>
              <li><i class="fa fa-phone"></i>+1 (901) 246‑8171</li>
              <li><i class="fa fa-clock-o"></i>Mon-Fri 8.00 am - 5:00 pm</li>
            </ul>
          </div>
          <div class="mt-topbar-right clearfix">
            <div class="appint-btn"><router-link :to="{name: 'Contact'}" class="site-button" tag="a" style="color: #000!important;">Book an Appointment</router-link></div>
          </div>
        </div>
      </div>
    </div>
    <div class="sticky-header main-bar-wraper">
      <div class="main-bar bg-white">
        <div class="container">
          <div class="logo-header">
            <div class="logo-header-inner logo-header-one">
              <a href="/">
                <img src="assets/images/seneca-logo.png" style="height: 70px" alt="" />
              </a>
            </div>
          </div>
          <!-- NAV Toggle Button -->
          <button data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggle collapsed">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <!-- ETRA Nav -->
          <div class="extra-nav">
            <div class="extra-cell">
              <a href="#" class="contact-slide-show"><i class="fa fa-angle-left arrow-animation"></i></a>
            </div>
          </div>
          <!-- ETRA Nav -->

          <!-- Contact Nav -->
          <div class="contact-slide-hide " style="background-image:url(assets/images/background/bg-5.png)">
            <div class="contact-nav">
              <a href="javascript:void(0)" class="contact_close">&times;</a>
              <div class="contact-nav-form p-a30">
                <div class="contact-info   m-b30">

                  <div class="mt-icon-box-wraper center p-b30">
                    <div class="icon-xs m-b20 scale-in-center"><i class="fa fa-phone"></i></div>
                    <div class="icon-content">
                      <h5 class="m-t0 font-weight-500">Phone number</h5>
                      <p>+1 (901) 246‑8171</p>
                    </div>
                  </div>

                  <div class="mt-icon-box-wraper center p-b30">
                    <div class="icon-xs m-b20 scale-in-center"><i class="fa fa-envelope"></i></div>
                    <div class="icon-content">
                      <h5 class="m-t0 font-weight-500">Email address</h5>
                      <p>hello@senecaconstructs.com</p>
                    </div>
                  </div>

                  <div class="mt-icon-box-wraper center p-b30">
                    <div class="icon-xs m-b20 scale-in-center"><i class="fa fa-map-marker"></i></div>
                    <div class="icon-content">
                      <h5 class="m-t0 font-weight-500">Address info</h5>
                      <p>09, Martin Street B190 Polo Alto, San Francisco</p>
                    </div>
                  </div>
                </div>
                <div class="full-social-bg">
                  <ul>
                    <li><a href="#" class="facebook"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="#" class="google"><i class="fa fa-google"></i></a></li>
                    <li><a href="#" class="instagram"><i class="fa fa-instagram"></i></a></li>
                    <li><a href="#" class="tumblr"><i class="fa fa-tumblr"></i></a></li>
                    <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="#" class="youtube"><i class="fa fa-youtube"></i></a></li>
                  </ul>
                </div>
                <div class="text-center">
                  <h4 class="font-weight-600">&copy; {{ year }} Seneca Constructions</h4>
                </div>
              </div>
            </div>
          </div>
          <!-- MAIN Vav -->
          <div class="header-nav navbar-collapse collapse">
            <ul class=" nav navbar-nav">
              <li>
                <a href="/" :class="isActiveRoute? 'active-link' : ''">Home</a>
              </li>
              <li>
              <router-link :to="{name: 'About'}" exact-active-class="active-link">About</router-link></li>
              <li><router-link :to="{name: 'Services'}" exact-active-class="active-link">Services</router-link></li>
              <li><router-link :to="{name: 'Contact'}" exact-active-class="active-link">Contact Us</router-link></li>
              <li><router-link :to="{name: 'Faq'}" exact-active-class="active-link">FAQ</router-link></li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </header>
  <!-- HEADER END -->
</template>

<script>

export default {
  name: 'mainHeader',
  data () {
    return {
      year: ''
    }
  },
  computed: {
    isActiveRoute () {
      return this.$route.name === 'Home'
    }
  },
  created () {
    this.year = new Date().getFullYear()
  }
}
</script>
<style>
.router-link-active, .active-link{
  color: #F5BF23 !important;
}
</style>
